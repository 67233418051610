
$base-color: #c6538c;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap');



@font-face {
  font-family: 'Akzidenz-Grotesk BQ';
  src: local('Akzidenz-Grotesk BQ Super'), local('Akzidenz-Grotesk-BQ-Super'),
      url('./AkzidenzGroteskBQ-Super/AkzidenzGroteskBQ-Super.woff2') format('woff2'),
      url('./AkzidenzGroteskBQ-Super/AkzidenzGroteskBQ-Super.woff') format('woff'),
      url('./AkzidenzGroteskBQ-Super/AkzidenzGroteskBQ-Super.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Akzidenz-Grotesk BQ';
  src: local('Akzidenz-Grotesk BQ Light'), local('Akzidenz-Grotesk-BQ-Light'),
      url('./AkzidenzGroteskBQ-Light/AkzidenzGroteskBQ-Light.woff2') format('woff2'),
      url('./AkzidenzGroteskBQ-Light/AkzidenzGroteskBQ-Light.woff') format('woff'),
      url('./AkzidenzGroteskBQ-Light/AkzidenzGroteskBQ-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Akzidenz-Grotesk BQ';
  src: local('Akzidenz-Grotesk BQ Medium'), local('Akzidenz-Grotesk-BQ-Medium'),
      url('./AkzidenzGroteskBQ-Medium/AkzidenzGroteskBQ-Medium.woff2') format('woff2'),
      url('./AkzidenzGroteskBQ-Medium/AkzidenzGroteskBQ-Medium.woff') format('woff'),
      url('./AkzidenzGroteskBQ-Medium/AkzidenzGroteskBQ-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Akzidenz-Grotesk BQ';
  src: local('Akzidenz-Grotesk BQ Regular'), local('Akzidenz-Grotesk-BQ-Regular'),
      url('./AkzidenzGroteskBQ-Reg/AkzidenzGroteskBQ-Reg.woff2') format('woff2'),
      url('./AkzidenzGroteskBQ-Reg/AkzidenzGroteskBQ-Reg.woff') format('woff'),
      url('./AkzidenzGroteskBQ-Reg/AkzidenzGroteskBQ-Reg.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

#root {
  width: 100%;
  height: 100%;
  background-attachment: fixed;

  overflow-y: scroll;
  overflow-x: hidden;

  background-image: url( '/public/images/back.svg' );
  background-origin: border-box;
  background-repeat: no-repeat;
  background-position: top 20em left 0px;
  background-size: cover;

  // background: rgb(51,152,224);
  // background: -moz-linear-gradient(99deg, rgba(51,152,224,1) 0%, rgba(184,226,250,1) 58%, rgba(238,211,162,1) 100%);
  // background: -webkit-linear-gradient(99deg, rgba(51,152,224,1) 0%, rgba(184,226,250,1) 58%, rgba(238,211,162,1) 100%);
  // background: linear-gradient(99deg, rgba(51,152,224,1) 0%, rgba(184,226,250,1) 58%, rgba(238,211,162,1) 100%);
  // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3398e0",endColorstr="#eed3a2",GradientType=1);

  
}

body {
  font-family: 'Akzidenz-Grotesk BQ';
  line-height: 1.5;
  font-weight: 300;
  font-size: 90%;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0;

  width: 100vw;
  height: 100vh;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 100%;
}

.cgu-link {
  position: absolute;
  color: #404040;
  bottom: 1em;
  transition: all 0.2s ease-in;

  &:hover {
    color: #707070;
  }
}

.main-header {
  font-size: 3em;
  margin-top: 2em;
  font-weight: 200;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  text-align: center;
  padding: .5em;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.latest-cases-box {
  width: 60%;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.results-length-content {
  text-align: center;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: 1.1em;
}

.btn-style {
  margin: .15em;
  border-radius: 3em;
  font-family: 'Akzidenz-Grotesk BQ';
  border: 1px solid black;
  padding-left: 1em;
  padding-right: 1em;
  text-decoration: none;
  text-align: center;
  background-color: black;
  color: white;
  cursor: pointer;

  transition: all 0.2s ease-in;

  &:hover {
    background-color: white;
    color: black;
  }
}

.team-link {
  text-decoration: none;
  padding-top: .5em;
  padding-bottom: .5em;
  font-weight: 600;
  font-size: 1.2em;
  position: absolute;
  right: 2em;
  top: 1em;
} 

.latest-case {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  margin: 0.5em;
  display: flex;
  flex-direction: column;
  width: 15em;
  padding: .5em;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h3 {
    margin: 0;
    padding: 0;
  }

  p {
    margin: 0;
    padding: 0;
  }

}

.info-btn {
  position: absolute;
  left: 1em;
  bottom: 1em;
  background-color: transparent;
  border: none;
  transition: all 0.2s ease-in;
  cursor: pointer;

  :hover {
    transform: rotate(15deg);
  }

  img {
    width: 2em;
    height: 2em;
    transition: all 0.2s ease-in;
  }

}

.info-box {
  text-align: center;
  text-wrap: wrap;
}

.see-more-latest {
  // margin: .15em;
  font-size: 1.1em;
  height: 2.5em;

}

.search {
  display: flex;
  margin-top: 2.5%;
  width: 75%;
  transition: all 0.3s ease-out;

  &.search-disabled {
    margin-top: 0%;
  }

}

#search-icon {
  width: 2.5em;
  height: 2.5em;
  transition: all 0.3s ease-in;

  &.hidden {
    opacity: 0;
    height: 0;
    width: 0;
    padding: 0;
  }
}

#search-bar {
  font-size: 1.7em;
  border: none;
  padding: 0.75em;
  text-align: center;
  background-color: transparent;
  width: 100%;

  border-top: 1px solid black;
  border-bottom: 1px solid black;

  font-family: 'Akzidenz-Grotesk BQ';
  transition: all 0.3s ease-in;
  font-weight: 300;

  &.hidden {
    opacity: 0;
    height: 0;
    padding: 0;
    border-width: 0;
  }

  ::placeholder {
    color: rgba(0, 0, 0, 0.7) !important;
    opacity: 1;
  }
  
  ::-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.7) !important;
  }
}



#search-btn {
  cursor: pointer;
  width: 10%;
  border: none;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  margin-left: 0.5em;
  background-color: transparent;
  transition: all 0.3s ease-in;

  > img:hover {
    transform: rotate(15deg);
  }

  &.hidden {
    opacity: 0;
    padding: 0;
    border-width: 0;
  }
}

#search-btn-field {
  cursor: pointer;
  width: 5%;
  border: none;
  border-left: 1px solid black;
  border-right: 1px solid black;
  margin-left: 0.5em;
  background-color: transparent;
  transition: all 0.3s ease-in;

  > img:hover {
    transform: rotate(15deg);
  }

  &.hidden {
    opacity: 0;
    width: 0;
    padding: 0;
    border-width: 0;
  }
}

#field-container {
  display: flex;
  width: 75%;
  justify-content: space-around;
  padding: 1em;
}

.field-button {
  padding: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  

  flex-grow: 1;
  background-color: transparent;

  cursor: pointer;
  border-radius: 1.5em;
  transition: background-color 0.3s ease;

  font-size: 1.15em;
  font-weight: 400;
  border: 1px solid black;

  &.field-search-enabled {
    padding-top: 1em;
    padding-bottom: 1em;
  }
  
}

.field-selected {
  background-color: black;
  color: white;
}

.august-logo {
  position: absolute;
  left: 0em;
  top: 1em;
  width: 15em;
}


#field-searches {
  width: 60%;
  margin-top: 1em;
}

.field-search {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1em;

  label {
    font-size: 1.6em;
    margin-right: 2em;
    padding-top: 0.2em;
  }

  input {
    width: 70%;
    
    font-size: 1.3em;
    padding: 0.5em;
    border: none;
    text-align: left;

    &.baseInput {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

  }

}

#result-box {
  width: 75%;
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 1em;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #70707034;
}

.result-element {
  width: 95%;
  margin-top: 1em;
  position: relative;
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid black;


  .see-doc-btn {
    position: absolute;
    right: -5em;
    bottom: 5%;
    width: 4em;
    height: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: black;
    border: none;
    cursor: pointer;
    transition: all 0.1s ease-in;

    &:hover {
      background-color: #343434;
    }

    img {
      width: 2em;
      height: 2em;
    }

  }

  .first-res-row {
    font-size: 1.25em;
    font-weight: 300;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.25em;

    .first-res-row-left {
      display: flex;
      align-items: center;

      div {
        margin-right: 1em;
      }

    }

  }

  .result-header {
    font-size: 1.5em;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
  }

  .refs-row {
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 0.9em;
    display: flex;
    justify-content: space-evenly;
  }

  .main-row {
    display: flex;
    justify-content: space-between;

    h2 {
      font-size: 1.1em;
      font-weight: 300;
      margin-bottom: 0;
      font-family: 'Poppins', sans-serif;
    }

    p {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .claimant-info {
      width: 30%;
    }

    .defendant-info {
      width: 30%;
    }

    .judges-info {
      width: 20%;
    }

  }

  .judge-row {
    margin-top: -1em;

    h2 {
      font-size: 1.1em;
      font-weight: 300;
      margin-right: 1em;
      margin-bottom: 0;
      font-family: 'Poppins', sans-serif;
    }

    .judge-info {
      display: flex;
    }

  }

  .rules-row {
    margin-top: 0.5em;
    display: flex;
    justify-content: space-between;
    width: 100%;

    h3 {
      font-size: 1.1em;
      font-weight: 300;
      font-family: 'Poppins', sans-serif;
      width: fit-content;
      margin-top: 0;
      margin-right: 0.5em;
      margin-bottom: 0;
    }

    p {
      margin-top: 0.2em;
      max-width: 45%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .rules-info {
      width: 100%;
      display: flex;
      justify-content: flex-start;
    }

  }

  &:last-child {
    border-bottom: none;
  }
}


.login-prompt {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-header {

  margin-top: 3em;
  font-size: 4em;
  font-weight: 800;
  font-family: 'Poppins';
  letter-spacing: .1em;
  text-transform: uppercase;

}



.login-input {
  border-radius: 0.25em;
  padding: 0.5em;
  font-size: 1.5em;
  width: 25%;
  font-family: 'Akzidenz-Grotesk BQ';
  border: 1px solid black;
  text-align: center;
}

.login-btn {
  margin-top: 1em;
  padding: 0.5em;
  font-size: 1.5em;
  font-family: 'Akzidenz-Grotesk BQ';
  border: 1px solid black;
  padding-left: 1em;
  padding-right: 1em;
  text-align: center;
  background-color: black;
  color: white;
  cursor: pointer;

  transition: all 0.3s ease-in;

  &:hover {
    background-color: white;
    color: black;
  }

}

.admin-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.second-admin-row {
  max-width: 98%;
  overflow-x: auto;
}

.first-admin-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5em;

  margin-bottom: 1em;

  img {
    height: 1.5em;
    max-width: 2em;
    margin-right: 1em;
    margin-left: 2em;

  }

  .admin-header {
    display: flex;
    align-items: center;
    font-family: 'Poppins', sans-serif;
  }

  .end-buttons {
    margin-right: 3em;

    button {
      margin: .5em;
      padding: 0.5em;
      font-size: 1.2em;
      border-radius: 3em;
      font-family: 'Akzidenz-Grotesk BQ';
      border: 1px solid black;
      padding-left: 1em;
      padding-right: 1em;
      text-align: center;
      background-color: black;
      color: white;
      cursor: pointer;

      transition: all 0.3s ease-in;

      &:hover:enabled {
        background-color: white;
        color: black;
      }

      &:disabled {
        background-color: #AEAEAE;
        cursor: not-allowed;
      }

    }

  }

}


.upload-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 30vh;
  background-color: transparent;


  label {
    width: 100%;
    height: 100%;
  }

  .upload-zone {
    cursor: pointer;
    margin-top: -1em;
    text-align: center;
    padding-top: 3em;
    width: 100%;
    height: 100%;
    transition: all 0.1s ease-in;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

  }

}

.upload-title {
  position: absolute;
  -webkit-text-stroke: 1px black;
  top: -3em;
  color: white;
  font-size: 2.5em;
  font-weight: 800;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0.5em;
}

.small-upload-text {
  text-decoration: underline;
  font-size: 1.1em;
  font-weight: 300;
}

.file-name {
  margin-right: 1em;
}

.upload-file {
  margin: .5em;
  padding: 0.5em;
  position: absolute;
  bottom: -4em;
  font-size: 1.5em;
  border-radius: 3em;
  font-family: 'Akzidenz-Grotesk BQ';
  border: 1px solid black;
  padding-left: 1em;
  padding-right: 1em;
  text-align: center;
  background-color: black;
  color: white;
  cursor: pointer;

  transition: all 0.3s ease-in;

  &:hover {
    background-color: white;
    color: black;
  }
}

.list-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60vh;
  padding: 1em;
  overflow-y: scroll;
}

.list-title {
  position: absolute;
  -webkit-text-stroke: 1px black;
  top: -3em;
  color: white;
  font-size: 2.5em;
  font-weight: 800;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0.5em;
}

.filter-input {
  border-radius: 0.25em;
  margin: 0.25em;
  padding: 0.15em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-bottom: 1em;
  font-size: 1.5em;
  width: 50%;
  font-family: 'Akzidenz-Grotesk BQ';
  border: 1px solid black;
  text-align: center;
}

.admin-file-list {
  width: 100%;
}

.admin-file-name {
  padding: .5em;
  font-size: 1.2em;
  display: flex;
  justify-content: space-between;


  &:not(:last-child) {
    border-bottom: 1px solid black;
  }

  .delete-btn {
    cursor: pointer;
    font-weight: 800;
    padding-top: 0;
    width: 2em;
    background-color: black;
    color: white;
    height: 2em;
    border: 1px solid black;

    transition: all 0.3s ease-in;

    &:hover {
      background-color: white;
      color: black;
    }
  }
}


.download-all {
  font-size: 1.3em;
  padding: .5em 1em .5em 1em;
  margin-bottom: 3em;
}

.about-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cgu-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-header {
  font-size: 4em;
  font-weight: 300;
  border-bottom: 2px solid black;
  border-top: 2px solid black;
  font-family: 'Akzidenz-Grotesk BQ';
  margin-bottom: 1em;
}

.cgupdf {
  width: 100%;
  height: 100%;
}

.peoples-row {
  display: flex;
  justify-content: space-evenly;
  width: 80%;

  .people-card {
    display: flex;
    flex-direction: column;
    align-items: center;

    .profile-pic {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      width: 16em;
      height: 16em;
    }

    .name-header {
      font-size: 1.2em;
      margin-top: 1em;
      color: black;
      text-decoration: none;
      border: 1px solid black;
      font-weight: 600;
      padding: .25em 1em .25em 1em;
      transition: all 0.1s ease-in;

      img {
        width: .8em;
        height: .8em;
        margin-left: 0.5em;
      }

      &:hover {
        background-color: whitesmoke;
      }

    }

    .email {
      color: black;
    }

  }

}

.about-info-header {
  font-size: 1.6em;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  margin-top: 4em;
}

.about-info-p {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: rgba(255, 255, 255, 1);
  font-size: 1.35em;
  font-weight: 300;
  padding: 2em;
  margin-top: 1em;
  width: 60%;
  margin-bottom: 5em;
}

.more-fields-btn {

  border-radius: 3em;
  font-family: 'Akzidenz-Grotesk BQ';
  border: 1px solid black;
  width: 3em;
  height: 3em;
  text-decoration: none;
  text-align: center;
  background-color: white;
  cursor: pointer;

  transition: all 0.2s ease-in;

  img {
    width: 1.5em;
    height: 1.5em;
  }

  &:hover {
    background-color: whitesmoke
  }

}


@import 'queries.scss';