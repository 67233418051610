

$breakpoints: (
    xsmall: 400px,
    small: 576px,
    medium: 768px,
    large: 992px,
    xlarge: 1250px,
);

@media screen and (max-width: map-get($breakpoints, xlarge)) {
    body {
        font-size: 90%;
    }

    .latest-cases-box {
        width: 80%;
    }

    #field-container {
        width: 85%;
    }
}

@media screen and (max-width: map-get($breakpoints, large)) {
    body {
        font-size: 90%;
    }

    .latest-cases-box {
        width: 95%;

    }

    .peoples-row {
        flex-direction: column;
    }

    .about-info-p {
        width: 90%;
    }

    .about-info-header {
        flex-wrap: wrap;
        margin-left: 2em;
        margin-right: 2em;
    }

    #field-container {
        width: 100%;

        .more-fields-btn {
            margin-right: 0.5em;
            width: 2.5em;
            height: 2.5em;

            img {
                width: 1.25em;
                height: 1.25em;
            }
        }
    }

    .main-header {
        font-size: 3em;
        margin-top: 2em;
    }
}

@media screen and (max-width: map-get($breakpoints, medium)) {
    body {
        font-size: 80%;
    }

    .latest-cases-box {
        width: 100%;
    }

    .main-header {
        font-size: 3em;
        margin-top: 2em;
    }

    .latest-cases-box {
        width: 90%;
        flex-wrap: wrap;
        margin-bottom: 0;

        .latest-case {
            flex: 1 0 25%;

            div {
                flex-direction: column;

                p {
                    display: none;
                }
            }

            p {
                display: flex;
                justify-content: center;
            }

        }

        .see-more-latest {
            font-size: 1.3em;
        }

    }


    .search {
        width: 100%;

        #search-icon {
            width: 1.75em;
            height: 1.75em;
        }


    }   

    #field-container {
        width: 100%;
        flex-wrap: wrap;
        font-size: 1.1em;

        .field-button {
            margin-bottom: .5em;
            width: 20%;
        }

        .more-fields-btn {
            width: 2.25em;
            height: 2.25em;
            margin-right: 0.5em;

            img {
                width: 1.2em;
                height: 1.2em;
            }
        }

    }

    #field-searches {
        width: 95%;
        font-size: 1em;

        label {
            margin-right: .5em;
        }

    }

    #result-box {
        width: 100%; 
        font-size: 110%;

        .see-doc-btn {
            right: -1em;
            bottom: .5em;
            width: 2.5em;
            height: 2.5em;

            img {
                width: 1.5em;
                height: 1.5em;
            }

        }
    }
}

@media screen and (max-width: map-get($breakpoints, small)) {
    body {
        font-size: 70%;
    }

    .main-header {
        margin-top: 2.5em;
    }

    

}

@media screen and (max-width: map-get($breakpoints, xsmall)) {
    body {
        font-size: 60%;
    }

    .main-header {
        font-size: 3em;
        margin-top: 2.5em;
    }

    .latest-cases-box {
        width: 90%;
        flex-wrap: wrap;
        margin-bottom: 1.5em;

        .latest-case {
            flex: 1 0 25%;

            div {
                flex-direction: column;

                p {
                    display: none;
                }
            }

            p {
                display: flex;
                justify-content: center;
            }

        }

        .see-more-latest {
            font-size: 1.3em;
        }

    }


    .search {
        width: 100%;

        #search-icon {
            width: 1.75em;
            height: 1.75em;
        }

        &.search-disabled {
            margin-top: -4em;
        }

    }   

    #field-container {
        width: 100%;
        flex-wrap: wrap;
        font-size: 1.1em;

        .field-button {
            margin-bottom: .5em;
            width: fit-content;
        }

        .more-fields-btn {
            width: 2em;
            height: 2em;

            img {
                width: 1em;
                height: 1em;
            }
        }

    }

    #field-searches {
        width: 95%;
        font-size: 1em;

        label {
            margin-right: .5em;
        }

    }

    #result-box {
        width: 100%; 
        font-size: 110%;

        .see-doc-btn {
            right: -1em;
            bottom: .5em;
            width: 2.5em;
            height: 2.5em;

            img {
                width: 1.5em;
                height: 1.5em;
            }

        }
    }

    .about-header {
        margin-top: 2em;
    }

    .team-link {
        font-size: 1.5em;
    }

}





