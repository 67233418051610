@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap");
@font-face {
  font-family: "Akzidenz-Grotesk BQ";
  src: local("Akzidenz-Grotesk BQ Super"), local("Akzidenz-Grotesk-BQ-Super"), url("./AkzidenzGroteskBQ-Super/AkzidenzGroteskBQ-Super.woff2") format("woff2"), url("./AkzidenzGroteskBQ-Super/AkzidenzGroteskBQ-Super.woff") format("woff"), url("./AkzidenzGroteskBQ-Super/AkzidenzGroteskBQ-Super.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Akzidenz-Grotesk BQ";
  src: local("Akzidenz-Grotesk BQ Light"), local("Akzidenz-Grotesk-BQ-Light"), url("./AkzidenzGroteskBQ-Light/AkzidenzGroteskBQ-Light.woff2") format("woff2"), url("./AkzidenzGroteskBQ-Light/AkzidenzGroteskBQ-Light.woff") format("woff"), url("./AkzidenzGroteskBQ-Light/AkzidenzGroteskBQ-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Akzidenz-Grotesk BQ";
  src: local("Akzidenz-Grotesk BQ Medium"), local("Akzidenz-Grotesk-BQ-Medium"), url("./AkzidenzGroteskBQ-Medium/AkzidenzGroteskBQ-Medium.woff2") format("woff2"), url("./AkzidenzGroteskBQ-Medium/AkzidenzGroteskBQ-Medium.woff") format("woff"), url("./AkzidenzGroteskBQ-Medium/AkzidenzGroteskBQ-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Akzidenz-Grotesk BQ";
  src: local("Akzidenz-Grotesk BQ Regular"), local("Akzidenz-Grotesk-BQ-Regular"), url("./AkzidenzGroteskBQ-Reg/AkzidenzGroteskBQ-Reg.woff2") format("woff2"), url("./AkzidenzGroteskBQ-Reg/AkzidenzGroteskBQ-Reg.woff") format("woff"), url("./AkzidenzGroteskBQ-Reg/AkzidenzGroteskBQ-Reg.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
#root {
  width: 100%;
  height: 100%;
  background-attachment: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  background-image: url("/public/images/back.svg");
  background-origin: border-box;
  background-repeat: no-repeat;
  background-position: top 20em left 0px;
  background-size: cover;
}

body {
  font-family: "Akzidenz-Grotesk BQ";
  line-height: 1.5;
  font-weight: 300;
  font-size: 90%;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  width: 100vw;
  height: 100vh;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 100%;
}

.cgu-link {
  position: absolute;
  color: #404040;
  bottom: 1em;
  transition: all 0.2s ease-in;
}
.cgu-link:hover {
  color: #707070;
}

.main-header {
  font-size: 3em;
  margin-top: 2em;
  font-weight: 200;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  text-align: center;
  padding: 0.5em;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.latest-cases-box {
  width: 60%;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.results-length-content {
  text-align: center;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: 1.1em;
}

.btn-style {
  margin: 0.15em;
  border-radius: 3em;
  font-family: "Akzidenz-Grotesk BQ";
  border: 1px solid black;
  padding-left: 1em;
  padding-right: 1em;
  text-decoration: none;
  text-align: center;
  background-color: black;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease-in;
}
.btn-style:hover {
  background-color: white;
  color: black;
}

.team-link {
  text-decoration: none;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-weight: 600;
  font-size: 1.2em;
  position: absolute;
  right: 2em;
  top: 1em;
}

.latest-case {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  margin: 0.5em;
  display: flex;
  flex-direction: column;
  width: 15em;
  padding: 0.5em;
}
.latest-case div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.latest-case h3 {
  margin: 0;
  padding: 0;
}
.latest-case p {
  margin: 0;
  padding: 0;
}

.info-btn {
  position: absolute;
  left: 1em;
  bottom: 1em;
  background-color: transparent;
  border: none;
  transition: all 0.2s ease-in;
  cursor: pointer;
}
.info-btn :hover {
  transform: rotate(15deg);
}
.info-btn img {
  width: 2em;
  height: 2em;
  transition: all 0.2s ease-in;
}

.info-box {
  text-align: center;
  text-wrap: wrap;
}

.see-more-latest {
  font-size: 1.1em;
  height: 2.5em;
}

.search {
  display: flex;
  margin-top: 2.5%;
  width: 75%;
  transition: all 0.3s ease-out;
}
.search.search-disabled {
  margin-top: 0%;
}

#search-icon {
  width: 2.5em;
  height: 2.5em;
  transition: all 0.3s ease-in;
}
#search-icon.hidden {
  opacity: 0;
  height: 0;
  width: 0;
  padding: 0;
}

#search-bar {
  font-size: 1.7em;
  border: none;
  padding: 0.75em;
  text-align: center;
  background-color: transparent;
  width: 100%;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  font-family: "Akzidenz-Grotesk BQ";
  transition: all 0.3s ease-in;
  font-weight: 300;
}
#search-bar.hidden {
  opacity: 0;
  height: 0;
  padding: 0;
  border-width: 0;
}
#search-bar ::-moz-placeholder {
  color: rgba(0, 0, 0, 0.7) !important;
  opacity: 1;
}
#search-bar ::placeholder {
  color: rgba(0, 0, 0, 0.7) !important;
  opacity: 1;
}
#search-bar ::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.7) !important;
}

#search-btn {
  cursor: pointer;
  width: 10%;
  border: none;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  margin-left: 0.5em;
  background-color: transparent;
  transition: all 0.3s ease-in;
}
#search-btn > img:hover {
  transform: rotate(15deg);
}
#search-btn.hidden {
  opacity: 0;
  padding: 0;
  border-width: 0;
}

#search-btn-field {
  cursor: pointer;
  width: 5%;
  border: none;
  border-left: 1px solid black;
  border-right: 1px solid black;
  margin-left: 0.5em;
  background-color: transparent;
  transition: all 0.3s ease-in;
}
#search-btn-field > img:hover {
  transform: rotate(15deg);
}
#search-btn-field.hidden {
  opacity: 0;
  width: 0;
  padding: 0;
  border-width: 0;
}

#field-container {
  display: flex;
  width: 75%;
  justify-content: space-around;
  padding: 1em;
}

.field-button {
  padding: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  flex-grow: 1;
  background-color: transparent;
  cursor: pointer;
  border-radius: 1.5em;
  transition: background-color 0.3s ease;
  font-size: 1.15em;
  font-weight: 400;
  border: 1px solid black;
}
.field-button.field-search-enabled {
  padding-top: 1em;
  padding-bottom: 1em;
}

.field-selected {
  background-color: black;
  color: white;
}

.august-logo {
  position: absolute;
  left: 0em;
  top: 1em;
  width: 15em;
}

#field-searches {
  width: 60%;
  margin-top: 1em;
}

.field-search {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1em;
}
.field-search label {
  font-size: 1.6em;
  margin-right: 2em;
  padding-top: 0.2em;
}
.field-search input {
  width: 70%;
  font-size: 1.3em;
  padding: 0.5em;
  border: none;
  text-align: left;
}
.field-search input.baseInput {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

#result-box {
  width: 75%;
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 1em;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid rgba(112, 112, 112, 0.2039215686);
}

.result-element {
  width: 95%;
  margin-top: 1em;
  position: relative;
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid black;
}
.result-element .see-doc-btn {
  position: absolute;
  right: -5em;
  bottom: 5%;
  width: 4em;
  height: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: black;
  border: none;
  cursor: pointer;
  transition: all 0.1s ease-in;
}
.result-element .see-doc-btn:hover {
  background-color: #343434;
}
.result-element .see-doc-btn img {
  width: 2em;
  height: 2em;
}
.result-element .first-res-row {
  font-size: 1.25em;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.25em;
}
.result-element .first-res-row .first-res-row-left {
  display: flex;
  align-items: center;
}
.result-element .first-res-row .first-res-row-left div {
  margin-right: 1em;
}
.result-element .result-header {
  font-size: 1.5em;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
.result-element .refs-row {
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 0.9em;
  display: flex;
  justify-content: space-evenly;
}
.result-element .main-row {
  display: flex;
  justify-content: space-between;
}
.result-element .main-row h2 {
  font-size: 1.1em;
  font-weight: 300;
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
}
.result-element .main-row p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.result-element .main-row .claimant-info {
  width: 30%;
}
.result-element .main-row .defendant-info {
  width: 30%;
}
.result-element .main-row .judges-info {
  width: 20%;
}
.result-element .judge-row {
  margin-top: -1em;
}
.result-element .judge-row h2 {
  font-size: 1.1em;
  font-weight: 300;
  margin-right: 1em;
  margin-bottom: 0;
  font-family: "Poppins", sans-serif;
}
.result-element .judge-row .judge-info {
  display: flex;
}
.result-element .rules-row {
  margin-top: 0.5em;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.result-element .rules-row h3 {
  font-size: 1.1em;
  font-weight: 300;
  font-family: "Poppins", sans-serif;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 0;
  margin-right: 0.5em;
  margin-bottom: 0;
}
.result-element .rules-row p {
  margin-top: 0.2em;
  max-width: 45%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.result-element .rules-row .rules-info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.result-element:last-child {
  border-bottom: none;
}

.login-prompt {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-header {
  margin-top: 3em;
  font-size: 4em;
  font-weight: 800;
  font-family: "Poppins";
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.login-input {
  border-radius: 0.25em;
  padding: 0.5em;
  font-size: 1.5em;
  width: 25%;
  font-family: "Akzidenz-Grotesk BQ";
  border: 1px solid black;
  text-align: center;
}

.login-btn {
  margin-top: 1em;
  padding: 0.5em;
  font-size: 1.5em;
  font-family: "Akzidenz-Grotesk BQ";
  border: 1px solid black;
  padding-left: 1em;
  padding-right: 1em;
  text-align: center;
  background-color: black;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-in;
}
.login-btn:hover {
  background-color: white;
  color: black;
}

.admin-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.second-admin-row {
  max-width: 98%;
  overflow-x: auto;
}

.first-admin-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5em;
  margin-bottom: 1em;
}
.first-admin-row img {
  height: 1.5em;
  max-width: 2em;
  margin-right: 1em;
  margin-left: 2em;
}
.first-admin-row .admin-header {
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
}
.first-admin-row .end-buttons {
  margin-right: 3em;
}
.first-admin-row .end-buttons button {
  margin: 0.5em;
  padding: 0.5em;
  font-size: 1.2em;
  border-radius: 3em;
  font-family: "Akzidenz-Grotesk BQ";
  border: 1px solid black;
  padding-left: 1em;
  padding-right: 1em;
  text-align: center;
  background-color: black;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-in;
}
.first-admin-row .end-buttons button:hover:enabled {
  background-color: white;
  color: black;
}
.first-admin-row .end-buttons button:disabled {
  background-color: #AEAEAE;
  cursor: not-allowed;
}

.upload-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 30vh;
  background-color: transparent;
}
.upload-popup label {
  width: 100%;
  height: 100%;
}
.upload-popup .upload-zone {
  cursor: pointer;
  margin-top: -1em;
  text-align: center;
  padding-top: 3em;
  width: 100%;
  height: 100%;
  transition: all 0.1s ease-in;
}
.upload-popup .upload-zone:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.upload-title {
  position: absolute;
  -webkit-text-stroke: 1px black;
  top: -3em;
  color: white;
  font-size: 2.5em;
  font-weight: 800;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0.5em;
}

.small-upload-text {
  text-decoration: underline;
  font-size: 1.1em;
  font-weight: 300;
}

.file-name {
  margin-right: 1em;
}

.upload-file {
  margin: 0.5em;
  padding: 0.5em;
  position: absolute;
  bottom: -4em;
  font-size: 1.5em;
  border-radius: 3em;
  font-family: "Akzidenz-Grotesk BQ";
  border: 1px solid black;
  padding-left: 1em;
  padding-right: 1em;
  text-align: center;
  background-color: black;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-in;
}
.upload-file:hover {
  background-color: white;
  color: black;
}

.list-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60vh;
  padding: 1em;
  overflow-y: scroll;
}

.list-title {
  position: absolute;
  -webkit-text-stroke: 1px black;
  top: -3em;
  color: white;
  font-size: 2.5em;
  font-weight: 800;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0.5em;
}

.filter-input {
  border-radius: 0.25em;
  margin: 0.25em;
  padding: 0.15em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-bottom: 1em;
  font-size: 1.5em;
  width: 50%;
  font-family: "Akzidenz-Grotesk BQ";
  border: 1px solid black;
  text-align: center;
}

.admin-file-list {
  width: 100%;
}

.admin-file-name {
  padding: 0.5em;
  font-size: 1.2em;
  display: flex;
  justify-content: space-between;
}
.admin-file-name:not(:last-child) {
  border-bottom: 1px solid black;
}
.admin-file-name .delete-btn {
  cursor: pointer;
  font-weight: 800;
  padding-top: 0;
  width: 2em;
  background-color: black;
  color: white;
  height: 2em;
  border: 1px solid black;
  transition: all 0.3s ease-in;
}
.admin-file-name .delete-btn:hover {
  background-color: white;
  color: black;
}

.download-all {
  font-size: 1.3em;
  padding: 0.5em 1em 0.5em 1em;
  margin-bottom: 3em;
}

.about-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cgu-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-header {
  font-size: 4em;
  font-weight: 300;
  border-bottom: 2px solid black;
  border-top: 2px solid black;
  font-family: "Akzidenz-Grotesk BQ";
  margin-bottom: 1em;
}

.cgupdf {
  width: 100%;
  height: 100%;
}

.peoples-row {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
}
.peoples-row .people-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.peoples-row .people-card .profile-pic {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 16em;
  height: 16em;
}
.peoples-row .people-card .name-header {
  font-size: 1.2em;
  margin-top: 1em;
  color: black;
  text-decoration: none;
  border: 1px solid black;
  font-weight: 600;
  padding: 0.25em 1em 0.25em 1em;
  transition: all 0.1s ease-in;
}
.peoples-row .people-card .name-header img {
  width: 0.8em;
  height: 0.8em;
  margin-left: 0.5em;
}
.peoples-row .people-card .name-header:hover {
  background-color: whitesmoke;
}
.peoples-row .people-card .email {
  color: black;
}

.about-info-header {
  font-size: 1.6em;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-top: 4em;
}

.about-info-p {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  background-color: rgb(255, 255, 255);
  font-size: 1.35em;
  font-weight: 300;
  padding: 2em;
  margin-top: 1em;
  width: 60%;
  margin-bottom: 5em;
}

.more-fields-btn {
  border-radius: 3em;
  font-family: "Akzidenz-Grotesk BQ";
  border: 1px solid black;
  width: 3em;
  height: 3em;
  text-decoration: none;
  text-align: center;
  background-color: white;
  cursor: pointer;
  transition: all 0.2s ease-in;
}
.more-fields-btn img {
  width: 1.5em;
  height: 1.5em;
}
.more-fields-btn:hover {
  background-color: whitesmoke;
}

@media screen and (max-width: 1250px) {
  body {
    font-size: 90%;
  }
  .latest-cases-box {
    width: 80%;
  }
  #field-container {
    width: 85%;
  }
}
@media screen and (max-width: 992px) {
  body {
    font-size: 90%;
  }
  .latest-cases-box {
    width: 95%;
  }
  .peoples-row {
    flex-direction: column;
  }
  .about-info-p {
    width: 90%;
  }
  .about-info-header {
    flex-wrap: wrap;
    margin-left: 2em;
    margin-right: 2em;
  }
  #field-container {
    width: 100%;
  }
  #field-container .more-fields-btn {
    margin-right: 0.5em;
    width: 2.5em;
    height: 2.5em;
  }
  #field-container .more-fields-btn img {
    width: 1.25em;
    height: 1.25em;
  }
  .main-header {
    font-size: 3em;
    margin-top: 2em;
  }
}
@media screen and (max-width: 768px) {
  body {
    font-size: 80%;
  }
  .latest-cases-box {
    width: 100%;
  }
  .main-header {
    font-size: 3em;
    margin-top: 2em;
  }
  .latest-cases-box {
    width: 90%;
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  .latest-cases-box .latest-case {
    flex: 1 0 25%;
  }
  .latest-cases-box .latest-case div {
    flex-direction: column;
  }
  .latest-cases-box .latest-case div p {
    display: none;
  }
  .latest-cases-box .latest-case p {
    display: flex;
    justify-content: center;
  }
  .latest-cases-box .see-more-latest {
    font-size: 1.3em;
  }
  .search {
    width: 100%;
  }
  .search #search-icon {
    width: 1.75em;
    height: 1.75em;
  }
  #field-container {
    width: 100%;
    flex-wrap: wrap;
    font-size: 1.1em;
  }
  #field-container .field-button {
    margin-bottom: 0.5em;
    width: 20%;
  }
  #field-container .more-fields-btn {
    width: 2.25em;
    height: 2.25em;
    margin-right: 0.5em;
  }
  #field-container .more-fields-btn img {
    width: 1.2em;
    height: 1.2em;
  }
  #field-searches {
    width: 95%;
    font-size: 1em;
  }
  #field-searches label {
    margin-right: 0.5em;
  }
  #result-box {
    width: 100%;
    font-size: 110%;
  }
  #result-box .see-doc-btn {
    right: -1em;
    bottom: 0.5em;
    width: 2.5em;
    height: 2.5em;
  }
  #result-box .see-doc-btn img {
    width: 1.5em;
    height: 1.5em;
  }
}
@media screen and (max-width: 576px) {
  body {
    font-size: 70%;
  }
  .main-header {
    margin-top: 2.5em;
  }
}
@media screen and (max-width: 400px) {
  body {
    font-size: 60%;
  }
  .main-header {
    font-size: 3em;
    margin-top: 2.5em;
  }
  .latest-cases-box {
    width: 90%;
    flex-wrap: wrap;
    margin-bottom: 1.5em;
  }
  .latest-cases-box .latest-case {
    flex: 1 0 25%;
  }
  .latest-cases-box .latest-case div {
    flex-direction: column;
  }
  .latest-cases-box .latest-case div p {
    display: none;
  }
  .latest-cases-box .latest-case p {
    display: flex;
    justify-content: center;
  }
  .latest-cases-box .see-more-latest {
    font-size: 1.3em;
  }
  .search {
    width: 100%;
  }
  .search #search-icon {
    width: 1.75em;
    height: 1.75em;
  }
  .search.search-disabled {
    margin-top: -4em;
  }
  #field-container {
    width: 100%;
    flex-wrap: wrap;
    font-size: 1.1em;
  }
  #field-container .field-button {
    margin-bottom: 0.5em;
    width: -moz-fit-content;
    width: fit-content;
  }
  #field-container .more-fields-btn {
    width: 2em;
    height: 2em;
  }
  #field-container .more-fields-btn img {
    width: 1em;
    height: 1em;
  }
  #field-searches {
    width: 95%;
    font-size: 1em;
  }
  #field-searches label {
    margin-right: 0.5em;
  }
  #result-box {
    width: 100%;
    font-size: 110%;
  }
  #result-box .see-doc-btn {
    right: -1em;
    bottom: 0.5em;
    width: 2.5em;
    height: 2.5em;
  }
  #result-box .see-doc-btn img {
    width: 1.5em;
    height: 1.5em;
  }
  .about-header {
    margin-top: 2em;
  }
  .team-link {
    font-size: 1.5em;
  }
}/*# sourceMappingURL=main.css.map */